import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../utils/axios';
import Api from '../utils/apiUrls';
import { TaskState } from '../model/tasks.model';
// config
import { PAGINATION_LINK } from '../config-global';

const initialState: TaskState = {
  isLoading: false,
  isTaskLoading: false,
  isStatusLogLoading:false,
  tasksData: [],
  taskDetails: {},
  taskDocuments:{},
  statusLogs:{},
  locations:[],
  assignedTasks: [],
  tasksStatuses:[],
  error: {},
  totalDataInPage: 0,
  completed_count: 0,
  in_progress_count: 0,
  mentor_verified_count: 0,
  posted_count: 0,
  ready_for_review_count: 0,
  draftCount: 0,
  activeCount: 0,
  inactiveCount: 0,
  totalCount:0
};

export const tasksSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    setTaskLoading(state, action) {
      state.isTaskLoading = action.payload;
    },
    setStatusLogLoading(state, action) {
      state.isStatusLogLoading = action.payload;
    },
    setTasksList(state, action) {
      const { tasks, tasks_count, active_count, inactive_count, draft_count, total_count  } = action.payload;
      state.tasksData = tasks;
      state.activeCount = active_count;
      state.inactiveCount = inactive_count;
      state.totalCount = total_count;
      state.draftCount = draft_count;
      state.totalDataInPage = tasks_count;
    },
    setAssignedTasks(state, action) {
      const { assigned_tasks, completed_count, in_progress_count, mentor_verified_count, posted_count, ready_for_review_count, assigned_tasks_count, total_count } = action.payload;
      state.assignedTasks = assigned_tasks;
      state.totalDataInPage = total_count;
      state.totalCount = assigned_tasks_count;
      state.completed_count = completed_count;
      state.in_progress_count = in_progress_count;
      state.mentor_verified_count = mentor_verified_count;
      state.posted_count = posted_count;
      state.ready_for_review_count = ready_for_review_count;
    },
    setTasksStatuses(state, action) {
      const { tasks_statuses } = action.payload;
      state.tasksStatuses = tasks_statuses;
    },
    setTasksDetails(state, action) {
      const { taskDetail } = action.payload;
      state.taskDetails = taskDetail;
    },
    setParentTaskDetails(state, action) {
      const { documents,status_change_logs } = action.payload;
      state.taskDetails = action.payload;
      state.taskDocuments = documents;
      state.statusLogs = status_change_logs;
    },
    setTaskStatusLogs(state, action) {
      const { status_logs } = action.payload;
      state.statusLogs = status_logs;
    },
    setLocationList(state, action) {
      state.locations = action.payload
    },
    setTasksError(state, action) {
      state.error = action.payload;
    },
  },
});
export const {
  setLoading,
  setTaskLoading,
  setLocationList,
  setStatusLogLoading,
  setTasksList,
  setTasksError,
  setTasksDetails,
  setAssignedTasks,
  setTasksStatuses,
  setParentTaskDetails,
  setTaskStatusLogs,
} = tasksSlice.actions;

export default tasksSlice.reducer;
const handleAsyncThunk = async (dispatch: any, request: any, thunkAction?: any) => {
  try {
    dispatch(setLoading(true));
    const response = await request();
    if (thunkAction) dispatch(thunkAction(response.data.data));
    dispatch(setLoading(false));
    return response;
  } catch (error) {
    dispatch(setLoading(false));
    if (error?.response?.status) {
      dispatch(setTasksError(error.message));
    } else {
      const errorMessages = Object.values(error?.data).flat();
      dispatch(setTasksError(errorMessages));
    }
    throw error;
  }
};

const taskHandleAsyncThunk = async (dispatch: any, request: any, thunkAction?: any) => {
  try {
    dispatch(setTaskLoading(true));
    dispatch(setTasksError(''));
    const response = await request();
    if (thunkAction) dispatch(thunkAction(response.data.data));
    dispatch(setTaskLoading(false));
    return response;
  } catch (error) {
    dispatch(setTaskLoading(false));
    if (error?.response?.status) {
      dispatch(setTasksError(error.message));
    } else {
      const errorMessages = Object.values(error?.data).flat();
      dispatch(setTasksError(errorMessages));
    }
    throw error;
  }
};

const StatusLogHandleAsyncThunk = async (dispatch: any, request: any, thunkAction?: any) => {
  try {
    dispatch(setStatusLogLoading(true));
    const response = await request();
    if (thunkAction) dispatch(thunkAction(response.data.data));
    dispatch(setStatusLogLoading(false));
    return response;
  } catch (error) {
    dispatch(setStatusLogLoading(false));
    if (error?.response?.status) {
      dispatch(setTasksError(error.message));
    } else {
      const errorMessages = Object.values(error?.data).flat();
      dispatch(setTasksError(errorMessages));
    }
    throw error;
  }
};

export const fetchTaskList = createAsyncThunk(
  'tasks/fetchTaskList',
  async (
    {
      page = 0,
      type = '',
      searchTerm = '',
      sort = '',
      sortKey = '',
      startDate = '',
      endDate = '',
      teamId = '',
      filterStatus = 'all'
    }: {  
        page?: number;
        employeeId?: string;
        searchTerm?: string;
        teamId?: string;
        sort?: string;
        sortKey?: string;
        type?: string;
        startDate?: any;
        endDate?: any
        filterStatus?: string;
      },
    { dispatch }
  ) => {

    const params = {
      search: searchTerm,
      skip: `${page * PAGINATION_LINK.PER_PAGE_COUNT}`,
      limit: `${PAGINATION_LINK.PER_PAGE_COUNT}`,
      sort,
      sort_key: sortKey,
      type,
      start_date: startDate,
      end_date: endDate,
      team_id: teamId,
      status:filterStatus.toLowerCase(),
    };

    const request = () => axios.get(Api.tasks, { params });
    await handleAsyncThunk(dispatch, request, setTasksList);

  }
);

export const FetchStatusLogs = createAsyncThunk(
  'task/status-logs',
  async (
    {
      taskId = '',
      searchTerm = '',
    }: { taskId?: string , searchTerm?: string
    },
    { dispatch }
  ) => {

    const params = {
      task_id: taskId,
      search: searchTerm,
    };
    const request = () => axios.get(Api.taskStatusLog, { params });
    await StatusLogHandleAsyncThunk(dispatch, request, setTaskStatusLogs);
  }
);


export const fetchTaskDetails = createAsyncThunk(
  'tasks/fetchTaskDetails',
  async (
    {
      taskId = 0,
    }: { taskId?: number },
    { dispatch }
  ) => {

    const params = {
      task_id: taskId,
    };

    const request = () => axios.get(Api.taskDetail, { params });
    await handleAsyncThunk(dispatch, request, setTasksDetails);

  }
);

export const deleteTask = createAsyncThunk('tasks/deleteTask', async (data: any, { dispatch }) => {
  try {
    await axios.post(Api.deleteTask, data);
    return { success: true }; // Return success flag
  } catch (error) {
    if (error?.data?.length === 0) {
      // Custom message handled
      dispatch(setTasksError(error.message));
    } else {
      // Specific error message handled
      const errorMessages = Object.values(error?.data).flat();
      dispatch(setTasksError(errorMessages));
    }
    throw error; // Throw the error to indicate failure
  }
});

export const updateTrainingTaskStatus = createAsyncThunk('task/update-status', async (data: any, { dispatch }) => {
  try {
    await axios.post(Api.updateTaskStatus, data);
    return { success: true }; // Return success flag
  } catch (error) {
    if (error?.data?.length === 0) {
      // Custom message handled
      dispatch(setTasksError(error.message));
    } else {
      // Specific error message handled
      const errorMessages = Object.values(error?.data).flat();
      dispatch(setTasksError(errorMessages));
    }
    throw error; // Throw the error to indicate failure
  }
});

export const changeTaskStatus = createAsyncThunk('task/status-change', async (data: any, { dispatch }) => {
  try {
    await axios.post(Api.changeStatus, data);
    return { success: true }; // Return success flag
  } catch (error) {
    if (error?.data?.length === 0) {
      // Custom message handled
      dispatch(setTasksError(error.message));
    } else {
      // Specific error message handled
      const errorMessages = Object.values(error?.data).flat();
      dispatch(setTasksError(errorMessages));
    }
    throw error; // Throw the error to indicate failure
  }
});


export const editTask = createAsyncThunk('tasks/editTasks', async (data: any, { dispatch }) => {

  try {
    data.task_id = data.uuid;
    delete data.uuid;
    const response = await axios.post(Api.editTask,  data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  }  catch (error) {
    if (error?.data?.length === 0) {
      // Custom message handled
      dispatch(setTasksError(error.message));
    } else {
      // Specific error message handled
      const errorMessages = Object.values(error?.data).flat();
      dispatch(setTasksError(errorMessages));
    }
    throw error; // Throw the error to indicate failure
  }
});


export const FetchTaskDetails = createAsyncThunk(
  'task/details',
  async (
    {
      taskId = '',
    }: { taskId?: string },
    { dispatch }
  ) => {

    const params = {
      task_id: taskId,
    };
    const request = () => axios.get(Api.taskDetail, { params });
    await taskHandleAsyncThunk(dispatch, request, setParentTaskDetails);
  }
);



export const addTask = createAsyncThunk('tasks/addTask', async (data: any, { dispatch }) => {
  try {
    // await axios.post(Api.addTask, data);
    const response = await axios.post(Api.addTask, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data; // Return success flag
  } catch (error) {
    dispatch(setLoading(false));
    if (error?.response?.data?.success === false) {
      dispatch(setTasksError(error.response.data));
    } else {
      const errorMessages = Object.values(error?.data).flat();
      dispatch(setTasksError(errorMessages));
    }
    throw error;
  }
});

export const fetchAssignedTasks = createAsyncThunk(
  'tasks/fetchAssignedTasks',
  async (
    {
      page = 0,
      type = '',
      searchTerm = '',
      sort = '',
      sortKey = '',
      startDate = '',
      status = '',
      teamId = '',
      endDate = '',
      traineeId = '',
      taskId = '',
    }: {  
        page?: number;
        status?: string;
        searchTerm?: string;
        teamId?: string;
        sort?: string;
        sortKey?: string;
        type?: string;
        startDate?: any;
        endDate?: any
        traineeId?: string | null;
        taskId?: string ;
      },
    { dispatch }
  ) => {

    const params = {
      search: searchTerm,
      skip: `${page * PAGINATION_LINK.PER_PAGE_COUNT}`,
      limit: `${PAGINATION_LINK.PER_PAGE_COUNT}`,
      sort,
      sort_key: sortKey,
      type,
      start_date: startDate,
      end_date: endDate,
      team_id: teamId,
      trainee_id:traineeId,
      task_id:taskId,
      status
    };
    const request = () => axios.get(Api.assignedTasks, { params });
    await handleAsyncThunk(dispatch, request, setAssignedTasks);

  }
);


export const FetchStatus = createAsyncThunk(
  'tasks/FetchStatus',
  async (
    {
      taskId = 0,
    }: { taskId?: number },
    { dispatch }
  ) => {

    const params = {
      task_id: taskId,
    };

    const request = () => axios.get(Api.taskStatuses, { params });
    await handleAsyncThunk(dispatch, request, setTasksStatuses);
  }
);

export const changeStatus = createAsyncThunk('tasks/changeStatus', async (data: any, { dispatch }) => {
  try {
    await axios.post(Api.changeStatus, data);
    return { success: true }; // Return success flag
  } catch (error) {
    if (error?.data?.length === 0) {
      // Custom message handled
      dispatch(setTasksError(error?.message));
    } else {
      // Specific error message handled
      const errorMessages = Object.values(error?.data).flat();
      dispatch(setTasksError(errorMessages));
    }
    throw error; // Throw the error to indicate failure
  }
});
