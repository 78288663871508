import { PermissionModule } from '../../model/roles.model';

export const formatInterviewer = (interview: any) => {
  const lead = interview?.lead_interviewers
    ? interview?.lead_interviewers.map((interviewer: any) => ({
        id: interviewer?.users?.uuid,
        label: `${interviewer?.users?.name}(${interviewer?.users?.email})`,
      }))
    : [];
  const sub_lead = interview?.other_interviewers
    ? interview?.other_interviewers.map((interviewer: any) => ({
        id: interviewer?.users?.uuid,
        label: `${interviewer?.users?.name}(${interviewer?.users?.email})`,
      }))
    : [];

  return { lead, sub_lead };
};

export const Interview_Comments = [
  { label: 'Can be hired', value: '0' },
  { label: 'Recommended for another round', value: '1' },
  { label: 'Can be considered for another technology', value: '2' },
  { label: 'Not meeting company standards', value: '3' },
  { label: 'Can be rejected', value: '4' },
  { label: 'Interview cancelled', value: '5'}
];

export const formatInValueToCapitalize = (str: string) => {
  if (str === undefined || str.length === 0) {
    return str;
  }
  // Replace underscores with spaces
  const stringWithSpaces = str.replace(/_/g, ' ');
  // Capitalize each word
  const formattedString = stringWithSpaces.replace(/\b\w/, (match) => match.toUpperCase());
  return formattedString;
};

export const findInteviewComments = (targetValue: any) => {
  const comment = Interview_Comments.find((item) => item.value === targetValue);
  return comment?.label || '';
};

const getLoggedInUserDetails = (): any => {
  const user = localStorage.getItem('user');
  return user ? JSON.parse(user) : {};
};

const getPermissions = (): any => {
  const permission = localStorage.getItem('permissions');
  return permission ? JSON.parse(permission) : {};
};

export const checkPermissions = (moduleName: PermissionModule, permission: string) => {
  const permissions = getPermissions();
  return permissions[moduleName]?.includes(permission) || false;
};

export const checkPermissionsByExcludingRoles = (moduleName: PermissionModule, permission: string, excludeRoles: Array<string>, employeeId: string = '') => {
  const user = getLoggedInUserDetails();
  return checkPermissions(moduleName, permission) && (user && !excludeRoles.includes(user.role_name) || employeeId === user?.uuid) || false;
};

export const photoHintText = 'Allowed *.jpeg, *.jpg, *.png, max size of 1 MB';

export const DocumentHintText = 'Allowed *.pdf, *.doc, *.docx, *.otd max size of 2.5 MB';
